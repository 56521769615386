.attendanceTableLog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 58%;
  height: 68%;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}

.ListlogDetails {
  height: 74%;
  overflow-y: auto;
}

.ListlogDetails::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.ListlogDetails::-webkit-scrollbar-track {
  background: transparent;
}

.ListlogDetails::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.logDetailsLoader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logDetailsLoader img {
  width: 80px;
}
