.Main_Section {
  width: 90%;
  height: 100%;
  margin: auto;
  display: flex;

  border-radius: 20px;
}

.Employee_Register {
  padding: 40px;
  width: 100%;
}

.Employee_Register h1,
.Employee_Register span {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.Employee_Register span {
  color: #eb0100;
}

.Employee_Details {
  width: 30%;
  height: calc(100% - 40px);
  margin: 40px 40px 0px 0px;
  padding-left: 40px;
  border-left: 1px solid #d9d9d9;
}

.Employee_Input_Section {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.Employee_Input_Section::-webkit-scrollbar {
  width: 0px;
}

.Employee_Inputs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.Input_Field.active {
  width: 430px;
  height: auto;
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-top: 0;
}

.Input_Field.margin {
  margin-top: 0;
}

.Input_Field.active p,
.Input_Field.file p {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #0a3b66;
  top: 90%;
  left: 0;
}

.Employee_Inputs input {
  width: 400px;
  height: 45px;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.Inline_Select {
  width: 30%;
  height: 62px;
  border: 1px solid transparent;
  padding: 8px 14px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.Inline_Input {
  width: 40% !important;
  border: 1px solid transparent !important;
  text-align: center;
}

.Custom_Select.active select {
  width: 400px;
  height: 45px;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.Input_Field h6 {
  position: absolute;
  top: 105%;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #eb0100;
  margin: 0;
}

.Upload {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Input_Field.file {
  width: 400px;
  height: auto;
  display: flex;
  padding: 0px 14px;
  justify-content: left;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  gap: 20px;
}

.FileNameLabel {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.Image_Shot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Capture_Shot {
  position: relative !important;
  transform: translate(0, 0) !important;
  width: 50% !important;
}

.Input_Field.file h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  color: #787878;
  cursor: pointer;
}

.Employee_Register button {
  width: 140px;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #0a3b66;
  border: 1px solid #0a3b66;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.Employee_Details h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.Employee_Box {
  width: auto;
  position: relative;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  display: flex;
  gap: 15px;
  cursor: pointer;
  margin-top: 20px;
}

.Employee_Box.margin {
  margin-top: 0;
}

.Employee_Snippet {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Employee_Description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Employee_Description h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.Employee_Description h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.Employee_Description span {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #787878;
}

.Know_More {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Employee_Snippet img {
  width: 100px;
}

.Employee_Done {
  width: auto;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.Add_More {
  width: auto;
  padding: 14px 30px;
  border-radius: 4px;
  background-color: #0a3b66;
  border: 1px solid #0a3b66;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.Im_Done {
  width: auto;
  padding: 14px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #0a3b66;
  color: #0a3b66;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.Employee_Detail_Section {
  position: absolute;
  width: 485px;
  height: 100vh;
  top: 0;
  right: -100%;
  border-radius: 20px 0px 0px 20px;
  background-color: #ffffff;
  overflow: hidden;
  animation: appear 1s ease forwards;
}

.Employee_Detail_Section.deactive {
  right: 0;
  animation: disappear 2s ease forwards;
}

@keyframes appear {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

@keyframes disappear {
  from {
    right: 0;
  }

  to {
    right: -100%;
  }
}

.Check_Preview {
  width: auto;
  height: 10%;
  background-color: #f0f0f0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Check_Preview h1 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.Check_Preview img {
  width: 30px;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.Employee_Contents {
  width: auto;
  height: calc(80% - 60px);
  display: flex;
  flex-direction: column;
  padding: 30px;
  padding-bottom: 0;
  justify-content: space-between;
}

.Employee_Submit {
  width: auto;
  height: 10%;
  margin: auto;
  display: flex;
}

.Employee_Submit button {
  width: auto;
  padding: 14px 50px;
  border-radius: 4px;
  background-color: #0a3b66;
  border: 1px solid #0a3b66;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  margin: auto;
}

.Employee_Profile {
  width: auto;
  height: 20%;
  display: flex;
  align-items: center;
  gap: 50px;
}

.Employee_Profile img {
  width: 120px;
}

.Employee_Buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Employee_Delete {
  width: auto;
  height: fit-content;
  padding: 8px 22px;
  border-radius: 8px;
  background-color: #eb0100;
  border: 1px solid #eb0100;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.Employee_Edit {
  width: auto;
  height: fit-content;
  padding: 8px 30px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #00a912;
  color: #00a912;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.Employee_Listings {
  width: auto;
  height: 78%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Employee_Listings::-webkit-scrollbar {
  width: 0px;
}

.Admin_Field.employee {
  grid-template-columns: 2fr 0.3fr 3fr;
}

.Admin_Field.employee label {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #484848;
}

.Admin_Field.employee span {
  font-weight: 600;
  color: #2e2e2e;
}

.Admin_Field.employee .active {
  color: #09355c;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  padding: 5px 15px 5px 3px;
}

.Main_Camera_Heading button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  padding: 14px 50px;
  border-radius: 4px;
  background-color: #0a3b66;
  border: 1px solid #0a3b66;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.Popup_Box.confirm.done {
  width: 500px;
  padding: 40px 20px;
  position: relative;
}

.Popup_Button.confirm.done button {
  width: auto;
}

select {
  appearance: none;
  /* Standard syntax */
  -webkit-appearance: none;
  /* Chrome, Safari, and newer versions of Opera */
  -moz-appearance: none;
  /* Firefox */
  background: transparent;
  /* Optional: make background transparent to remove arrow background */
  padding-right: 20px;
  /* Add padding to the right to accommodate your custom arrow */
}

.Dropdown_Arrow {
  height: 20px !important;
  width: 20px !important;
}

.Heading_Submit {
  margin-top: 50px;
}

.captureImage {
  border: none !important;
  outline: none !important;
  display: flex !important;
  padding: 0 !important;
}

.Capture_Shot {
  margin: 0 !important;
  padding: 0 !important;
}

.imgType {
  margin-top: 10px;
}

#uploadId {
  height: 45px;
}


@media only screen and (max-width: 1400px) {
  #editProfile {
    width: fit-content !important;
  }

  .Employee_Inputs,
  .Heading_Submit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}