
  .Input_container{
    margin: 20px 0;
  }
  .Input_container_row{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .Input_container_row select{
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgba(217, 217, 217, 1);
    width:400px;
    -webkit-appearance: none; 
    -moz-appearance: none;    
    appearance: none;       
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><path fill="black" d="M1.41.58L6 5.17 10.59.58 12 2l-6 6-6-6L1.41.58z"/></svg>'); 
    background-repeat: no-repeat;
    background-position: right 15px center; 
    background-size: 12px;
    cursor: pointer;
  }

.Input_container_child{
  position: relative;
}
.input_lebel{
  position: absolute;
  top:-10px;
  left:10px;
  background-color: white;
  color: rgba(120, 120, 120, 1);

}


  .nextBtn{
    margin: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  
  .nextBtn button{
    width: 160px;
    height: 48px;
    border-radius: 4px;
    background-color: var(--btn-color);
    color: var(--secondary-color);
    border: 0;
    outline: 0;
    cursor: pointer;
  }
  
  
  
  
  
  
  
  .inner_class {
    border : 1px solid #D9D9D9;
    width: 100%;
    border-radius: 10px;
  }

  .text_container {
    padding: 30px;
    color: #2E2E2E;
  }

  .text_container h4 {
    font-weight: 600;
  }

  .Spreadsheet_Container {
    margin-top: 20px;
    border: 1px solid #ccc;
    overflow-x: auto;
  }
  
 