.liveTracker {
  height: 100vh;
  width: 100%;

}

.liveTracker .one {
  height: 5vh;
  margin: 2px auto;
  width: 98%;
  display: flex;
  align-items: center;
}

.liveTracker .one h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: left;
}

.commonBorderForAll {
  margin: 2px auto;
  width: 98%;
  height: 80vh;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  
}

.commonBorderForAll .heading {
  padding: 10px;
}

.commonBorderForAll .heading h1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  text-align: left;
}

.listOfAttendanceEmployee {
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-bottom: 100px;
}
.liveTrackerLoder {
  padding: 10px;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.liveTrackerLoder img {
  width: 80px;
}
.listOfAttendanceEmployeeNotFound {
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  overflow-y: auto;
}

.listOfAttendanceEmployee::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.listOfAttendanceEmployee::-webkit-scrollbar-track {
  background: transparent;
}

.listOfAttendanceEmployee::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.listOfAttendanceEmployee .card {
  border-radius: 8px;
  height: fit-content;
  border: 1px solid #eeeeee;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 384px;
  margin: 20px 0;
}

.listOfAttendanceEmployee .card .upper {
  width: 184px;
  height: 244px;
 border-radius: 8px;
 position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.overlay .zoom-icon {
  font-size: 2rem;
  color: white;
}

.listOfAttendanceEmployee .card .upper:hover .overlay {
  opacity: 1;
}

#imgLiveTracker {
height: 100%;
width: 100%; 
border-radius: 7px;

}

.listOfAttendanceEmployee .card .lower {
  /* background-color: #787878; */
  margin-top: 10px;
  width: 100%;
}

.listOfAttendanceEmployee .card .lower .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.listOfAttendanceEmployee .card .lower .details .name_heading {
  width: 35%;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #787878;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listOfAttendanceEmployee .card .lower .details .dot {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listOfAttendanceEmployee .card .lower .details .name {
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  cursor: pointer;
}

.listOfAttendanceEmployee .card .lower .details .name::-webkit-scrollbar {
  width: 10px;
  height: 4px;

}

.listOfAttendanceEmployee .card .lower .details .name::-webkit-scrollbar-track {
  background: transparent;
}

.listOfAttendanceEmployee .card .lower .details .name::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.listOfAttendanceEmployee .card .lower .details .name b {
  font-size: 0.86rem;
}
.timeCheck {
  margin-top: 20px;
}

.timeCheck p:nth-child(1) {
  color: red;
 
}

.timeCheck p:nth-child(2) {
  color: #00a912;
  margin-top: 5px;
}
.viewImageInScale{
  width: 100% !important;
  height: 100vh;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  position: relative;
}

.viewImageChild1{
  padding: 40px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:#08233C;
}
.shimmer-container_liveTracker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.shimmer-button {
  width: 100px;
  height: 30px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 3.5s infinite linear;
  border-radius: 5px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@media screen and (max-width: 1366px){
  .commonBorderForAll {
    margin: 2px auto;
    width: 98%;
    height: 75vh;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    
  }
}
