.nominateHr {
    width: 100%;
    height: 100%;
    position: fixed;
    right: -1000px;
    top: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    transition: right 1s ease-in-out;
    display: flex;
    justify-content: end;
}

.nominateHr.open {
    right: 0;
}

.nominateHrModel {
    background: #FFFFFF;
    width: 28.1%;
    height: 100vh;
}

.navHr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px 1px 8px 0px #0000001F;
    height: 10vh;
}

.lowerHrSection {
    margin: 32px 0;
    height: 100%;
    width: 100%;
}

.parentBtnHr {
    margin-bottom: 5px;
    width: 98%;
    display: flex;
    justify-content: end;
}

.nominateHrBySelect {

    padding: 10px 25px;
    background: #09355C;
    cursor: pointer;
    border-radius: 4px;
    border: 0;
    outline: 0;
}
#chnageHrColorRemove{
    background-color: transparent;
    border: 2px solid #09355C;
    
}


.nominateHrBySelect span {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    color: white;
}
#chnageHrColorRemove span{
    color: #09355C;
}
.commonBorderForAllHr {
    width: 98%;
    height: 83.5vh;
    overflow: hidden;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-radius: 0 8px 8px 0;
}



.listOfEmployeeRightHr {
    width: 100%;
    height: 100%;
    border-left: 1px solid #d9d9d9;
}

.Employees_ListsHr {
    width: 100%;
    padding: 0 10px;
    height: 80%;
    overflow-y: auto;
}

.Employees_ListsHr::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.Employees_ListsHr::-webkit-scrollbar-track {
    background: transparent;
}

.Employees_ListsHr::-webkit-scrollbar-thumb {
    background-color: #bcbcbc;
    border-radius: 7px;
}

.totalEmployeeCountOfHrSection {
    margin: 2px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;

}