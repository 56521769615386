.spreadsheet_page {
  height: 100vh;
  width: 100%;
  background-color: #f1f1f1;
 
}
.spreadsheet_page_child{
  padding: 10px 0 !important;
  width:98%;
  height: 95%;
  margin: auto;
  overflow-x: auto;
}
.excelSheets_action_btn{
  width:98%;
  height: 5%;
}

.Spreadsheet__header, .Spreadsheet__cell {
  min-width: 118px !important;  /* Adjust width */
  padding: 10px !important;  /* Add padding for spacing */
}

.invalid-row {
  background-color: rgba(255, 0, 0, 0.3); /* Light red */
}
