.tabbed-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    margin: 2px auto;
    width: 98%;
    padding: 0 20px;
}

.tabs {
    background-color: #f0f0f0; /* Default background */
    color: black;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
    border-radius: 5px;
  }

  .tabs button {
    border: none;
    margin: 6px;
    padding: 10px 20px;
    font-weight: bold;
  }
  
  .tabs button.active {
    background-color: #003366; /* Dark Blue */
    color: white; /* White text for contrast */
    font-weight: bold;
    border-radius: 5px;
  }