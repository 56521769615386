.shimmer-wrapper-table {
  display: flex;
  flex-direction: column;
  margin: 2px auto;
  width: 98%;
  height: 95%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  overflow-y: auto;
}

.shimmer-wrapper-table::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.shimmer-wrapper-table::-webkit-scrollbar-track {
  background: transparent;
}

.shimmer-wrapper-table::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
}
.shimmer_table {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #eeeeee 0%,
    #dddddd 20%,
    #eeeeee 40%,
    #eeeeee 100%
  );
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: inline-block;
  position: relative;
  animation: shimmer_table 5s infinite linear;
}

@keyframes shimmer_table {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton-table {
  width: 100%;
  border-collapse: collapse;
}
.skeleton-table tr {
  border: 1px solid #ddd;
}

.skeleton-cell {
  width: 100%;
  height: 50px;
  margin: 5px;
  background-color: #ccc;
  border-radius: 4px;
}
