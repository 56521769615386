.Input_Field span {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #787878;
  right: 0;
  top: 110%;
  cursor: pointer;
}

.Main_Background {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(292.67deg, #0b3e6c 0.45%, #000000 100%);
}

.Left_Section,
.Right_Section {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Left_Section_Contents {
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Left_Section_Contents h1 {
  color: #ffffff;
  font-size: 56px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin:30px 0;
}

.Left_Section_Contents p {
  color: #ffffff;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.Right_Section_Contents {
  width: 510px;
  /* max-height: 610px; */
  height: 610px;

  padding: 35px;
  margin: auto;
  display: flex;
  flex-direction: column;

  align-items: center;
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}

.Form_Background {
  position: absolute;
  z-index: 1;
  /* left: 20%;
    max-height: 610px; */
  left: 30%;
  width: 480px;
  /* max-height: 610px;
    width: 550px; */
}

.Input_Box {
  width: 85%;
  height: auto;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}

.Input_Box h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-bottom: 0;
}

.Right_Section_Contents input {
  width: auto;
  height: 55px;
  padding: 8px 18px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

input:focus {
  outline: none;
}

.Input_Field {
  width: auto;
  position: relative;
  display: grid;
  margin-top: 50px;
}

.Label {
  padding: 2px 8px;
  color: #787878;
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  left: 2%;
  top: -15%;
  background-color: #ffffff;
  user-select: none;
}

/* .Label.disable {
  background: #efefef4d;
} */

.Input_Field p {
  position: absolute;
  top: 85%;
  left: 1%;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #eb0100;
}

.Right_Section_Contents button {
  width: 160px;
  height: 48px;
  color: #ffffff;
  border-radius: 4px;
  background-color: #0a3b66;
  border: 1px solid #0a3b66;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-top: 120px;
  cursor: pointer;
}

.OTP_Box {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 110%;
}

.OTP_Box h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.OTP_Box h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #d9d9d9;
  margin: 0;
}

.Email_Sent {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #01a601;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Input_Box h4 {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: #2e2e2e;
  margin: 0;
  position: absolute;
  top: 180px;
}

.Back_Button {
  position: absolute;
  bottom: 40px;
  left: 40px;
  cursor: pointer;
}

.Loader {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 0;
  left: 0;
  overflow: hidden;
}

.Loader img {
  width: 80px;
}

.Input_Field img {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  width: 30px;
  transform: translate(-50%, -50%);
}

header {
  width: 100vw;
  height: 10px;
  position: fixed;
  display: flex;
  background-color: transparent;
  align-items: center;
}

.Header_Component {
  width: 95%;
  margin: auto;
}
.Header_Component img {
  /* width: 300px; */
  padding: 10px;
}
@media screen and (min-width: 200px) and (max-width: 1400px) {
  .Header_Component img {
    width: 150px;
  
  }
 
  .Left_Section_Contents img{
    height: 300px;
    width: 300px;
  }
  
  .Left_Section_Contents h1 {
    color: #ffffff;
    font-size: 45px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
  }
  
  .Left_Section_Contents p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .Form_Background {
    position: absolute;
    z-index: 1;
   
    left: 23%;
    /* width: 460px; */
    max-height: 590px;
      /* width: 550px; */
  }
  .Right_Section_Contents {
    width: 460px;
    /* max-height: 610px; */
    height: 550px;
  
    padding: 35px;
    margin: auto;
    display: flex;
    flex-direction: column;
  
    align-items: center;
    border-radius: 20px;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
  }

}
