.adminLayout {
    height: 100vh !important;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;


}

.upper {
    height: 10vh;
    width: 100%;

}

.below {
    height: 90vh;
    width: 100%;
    display: flex;


}

.left {
    width: 20%;
    background-color: var(--primary-color);
}

.right {
    width: 80%;
    background-color: var(--secondary-color);
}