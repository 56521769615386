.Masters_Container {
  width: 100%;
  height: 94%;
  display: flex;
  padding: 0px 15px 37px 15px;
  color: #2E2E2E;
}

.Side_Bar {
  width: 20%;
  height: 100%;
  border : 1px solid #D9D9D9;
  border-right: none;
  border-radius: 10px 0px 0px 10px;
  background-color: #f0f0f0;
}

.Side_Menu {
  width: 80%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 40px;
}

.Side_Heading {
  width: auto;
  height: auto;
  display: flex;
  gap: 30px;
  align-items: center;
}

.Mark {
  width: 13px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
}

.Mark.active {
  background-color: #00a912;
}

.Mark_Name {
  width: 100%;
  padding: 8px 30px;
  background-color: transparent;
  border-radius: 18px;
  cursor: default;
}

.Mark_Name.active {
  background-color: #09355c;
}

.Mark_Name h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #bcbcbc;
}

.Mark_Name.active h2 {
  color: #ffffff;
}

.Mark_Name.active.next h2 {
  color: #ffffff;
}

.Mark_Name.next h2 {
  color: #2e2e2e;
  cursor: pointer;
}

.Main_Bar {
  width: 80%;
  height: 100%;
  margin: auto;
  display: flex;
  border-radius: 0px 10px 10px 1px;
  border: 1px solid #D9D9D9;
  border-left: none;
}

@media screen and (min-width: 200px) and (max-width: 1400px) {
  .Side_Menu {
    margin-bottom: 40px;
    height: calc(100% - 80px);
    overflow-y: scroll;
    padding-right: 15px;
  }

  .Side_Menu::-webkit-scrollbar {
    width: 4px;
  }

  .Side_Menu::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  .Side_Menu::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .Side_Menu::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }

  .Side_Heading {
    gap: 20px;
  }

  .Mark {
    width: 13px;
    height: 6px;
  }

  .Mark_Name h2 {
    font-size: 14px;
  }
}
