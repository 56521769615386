.shimmer-autoReports {
    display: flex;
    gap:10;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 15px;
    margin: 10px 0;
    width: 220px;
    height: 228px;
    animation: shimmer 1.5s infinite linear;
  }
  
  .shimmer-autoReports_child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .shimmer-shape {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmerAnimation 1.5s infinite;
    border-radius: 5px;
  }
  
  .shimmer-title {
    width: 120px;
    height: 20px;
    margin-bottom: 10px;
  }
  
  .shimmer-text {
    width: 100px;
    height: 15px;
    margin-bottom: 5px;
  }
  
  .shimmer-long-text {
    width: 200px;
    height: 15px;
    margin-bottom: 5px;
  }
  
  .shimmer-button {
    width: 80px;
    height: 30px;
    border-radius: 5px;
  }
  
  @keyframes shimmerAnimation {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  