.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    padding: 40px 20px;
    border-radius: 10px;
    position: relative;
    width: 460px;
    max-width: 90%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.model h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
}

.model p {
    margin: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center !important;
}


#nominateHrBtn {
    cursor: pointer;
    margin: 10px 0;
    background-color: #0A3B66 !important;
    padding: 10px 30px;
    outline: 0;
    border: 0;
    border-radius: 4px;
    
}

#nominateHrBtn span {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    color: white;
}


.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.employee-details-container.modal-open .employee-content {
    pointer-events: none;
}