.Main_Dashboard_Background {
  /* background-color: #363636; */
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.Main_Dashboard_Background::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.Main_Dashboard_Background::-webkit-scrollbar-track {
  background: transparent;
}

.Main_Dashboard_Background::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.Account_Details {
  /* width: calc(100% - 180px);
  height: calc(100% - 80px); */
  padding: 40px 90px;
  height: 100%;
  width: 100%;
}

.Admin_Account {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  gap: 25px;
}

.Admin_Account h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.Admin_Account img {
  cursor: pointer;
}

.Admin_Details {
  width: auto;
  /* height: calc(100% - 30px); */
  height: 100%;
  display: flex;
  /* overflow: scroll; */
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.Admin_Details::-webkit-scrollbar {
  width: 0px;
}

.Admin_Field {
  width: auto;
  height: auto;
  display: grid;
  grid-template-columns: 0.7fr 0.3fr 3fr;
}

.Admin_Field label {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #363636;
}

.Active_Icon {
  border: 3px solid #01a601;
  display: flex;
  border-radius: 50%;
}

.Active_Icon.inactive {
  border: 3px solid transparent;
}
