.autoReports {
  width: 220px;
  height: 228px;
  border-radius: 10px;
  border: 1px solid rgba(217, 217, 217, 1);
  overflow: hidden;
}
.autoReports_child {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.autoReports-one {
  width: 100%;
  height: 60%;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.autoReports-two {
  width: 100%;
  height: 25%;
  background: rgba(238, 238, 238, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.autoReports-three {
  width: 100%;
  height: 35%;
  height: 40px;
  background: rgba(9, 53, 92, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.autoReports-three p {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  letter-spacing: 0%;
  color: white;
}
.autoReports-two p {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0%;
  color: rgba(9, 53, 92, 1);
  text-align: left;
}
.autoReports-one-child {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}
.autoReports-one-child h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24.38px;
  letter-spacing: 0%;
  color: rgba(46, 46, 46, 1);
}

.autoReports-one-child p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.5px;
  letter-spacing: 0%;
  color: rgba(120, 120, 120, 1);
}
.autoReports-two_tag {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0%;
  color: rgba(120, 120, 120, 1) !important;
}

.shiftTimeHeading h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29.26px;
  letter-spacing: 0%;
  color: rgba(54, 54, 54, 1);
}
.Popup_Box_Shift {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1;
  padding: 30px 50px !important;
  background-color: #ffffff;
  border-radius: 8px;
  gap: 20px;
}

.autoReports_fields {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10;
}

.inputFields_shift {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5;
  margin: 5px 0px;
}

.inputFields_shift:nth-child(1) {
  height: 100%;
  width: 30%;
  white-space: nowrap !important;
}
.inputFields_shift:nth-child(2) {
  height: 100%;
  width: 60%;
}
.inputFields_shift:nth-child(2) input,
select {
  width: 280px;
  height: 48px;
  border-radius: 8px;
  border-width: 1px;
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 10px;
}
#timeInputFields {
  width: 280px;
  height: 48px;
  border-radius: 8px;
  border-width: 1px;
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 10px;
}
.inputFields_shift:nth-child(2) input,
select:focus {
  outline: none !important;
  border: 1px solid rgba(217, 217, 217, 1) !important;
}

.autoReportBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.autoReportBtn button {
  width: 130px;
  height: 48px;
  border-radius: 4px;
  background: rgba(10, 59, 102, 1);
  color: white;
  cursor: pointer;
  font-weight: 600;
  outline: 0;
  border: none;
}
