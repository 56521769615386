.Main_Heading {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
  }
  
  .Heading_Section,
  .Heading_Details {
    width: 50%;
    margin: 30px 80px 30px 80px;
  }
  
  .Heading_Section::-webkit-scrollbar,
  .Heading_Details::-webkit-scrollbar {
    width: 0px;
  }
  .Heading_Details {
    margin-left: 0;
    padding-left: 80px;
  }
  
  .Heading_Details {
    border-left: 1px solid #d9d9d9;
  }
  
  .Heading_Section h2,
  .Heading_Details h2 {
    font-weight: 700;
    line-height: normal;
    font-size: 16px;
    color: #2e2e2e;
    margin-bottom: 0;
  }
  
  .Heading_Section input,
  .Heading_Section select {
    width: auto;
    height: 45px;
    padding: 8px 14px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }
  
  .Heading_Section select {
    width: 100%;
    height: 45px;
    cursor: pointer;
  }
  
  select:focus {
    outline: none;
  }
  
  .Input_Boxes {
    width: auto;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-right: 15px;
  }
  
  .Input_Boxes::-webkit-scrollbar {
    width: 4px;
  }
  
  .Input_Boxes::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  .Input_Boxes::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .Input_Boxes::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 10px;
  }
  
  .All_Headings {
    width: auto;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 50px;
    padding-right: 15px;
  }
  
  .All_Headings::-webkit-scrollbar {
    width: 4px;
  }
  
  .All_Headings::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  .All_Headings::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .All_Headings::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 10px;
  }
  
  .Heading_Box {
    width: auto;
    padding: 16px 30px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    position: relative;
  }
  
  .Heading_Box h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .Heading_Box h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .Heading_Button {
    position: absolute;
    display: flex;
    gap: 40px;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 3%;
  }
  
  .Heading_Button img {
    cursor: pointer;
  }
  
  .Heading_Submit {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .Heading_Section button {
    width: 140px;
    padding: 10px 18px;
    margin: auto;
    border-radius: 4px;
    background-color: #0a3b66;
    border: 1px solid #0a3b66;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
  
  @media screen and (min-width: 200px) and (max-width: 1400px) {
    .Heading_Section,
    .Heading_Details {
      margin: 20px 50px 20px 50px;
    }
  
    .Heading_Section h2,
    .Heading_Details h2 {
      font-size: 16px;
    }
  
    .Heading_Section input,
    .Heading_Section select {
      height: 30px;
    }
  
    .Heading_Section select {
      height: 50px;
    }
  
    .Heading_Section button {
      margin-bottom: 0;
      font-size: 12px;
      width: 100px;
    }
  
    .Input_Boxes {
      height: calc(100% - 70px);
    }
  
    .Heading_Details {
      padding-left: 40px;
    }
  
    .All_Headings {
      margin-top: 30px;
    }
  
    .Heading_Box h3 {
      font-size: 16px;
    }
  
    .Heading_Box h4 {
      font-size: 12px;
    }
  
    .Heading_Box img {
      width: 18px;
    }
  }
  