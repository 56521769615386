.navbar {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-color);
  padding: 20px 30px;
  position: relative;
  z-index: 10;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.left_nav img {
  height: 100%;
  width: 100%;
}

.right_nav {
  display: flex;
  width: 58%;
  justify-content: end;
  align-items: center;
}

.subMenu_nav {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logoCompanyNavbar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.subMenu_nav .one span {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
}

.subMenu_nav .one {
  margin: 0 10px;
}

.menu_nav {
  cursor: pointer;
  margin: 0 20px;
}
.notification{
  position: relative;
}
.notification_number{
  position: absolute;
  top:-5px;
  right:-15px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: red;
  border:2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: small;
  font-weight: 600;
}
.userNameInLogo {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 800;
  padding: 5px;
}
@media screen and (max-width: 1300px) and (min-width: 1080px) {
  .right_nav {
    display: flex;
    width: 28%;
    justify-content: space-between;
    align-items: center;
    /* background-color: aqua; */
  }
}
