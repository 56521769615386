.shimmer-Box {
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: hidden;
  overflow-x: hidden;
}
.shimmer-Master {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 35px;
}

.shimmerMaster {
  width: auto;
  padding: 16px 30px;
  height: 40px;
  margin-top: 15px;
  border: 1px solid #ababab;
  border-radius: 6px;
  position: relative;
  background-image: linear-gradient(
    to right,
    #ffffff 0%,
    #f0f0f0 50%,
    #dcdcdc 100%
  );
  animation: shimmerYear 5s infinite linear;
}

@keyframes shimmerYear {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
